import React from 'react';

import Icon from 'components/UI/Icon';

import { special } from './styles.module.scss';

const SpecialLink = ({ id = 'link-to-linkedIn' }) => {
  return (
    <a
      href="https://www.linkedin.com/company/screver/"
      target="_blank"
      rel="noopener noreferrer"
      className={special}
      id={id}
    >
      <Icon name="social-in" />

      <span>Connect in LinkedIn</span>
    </a>
  );
};

export default SpecialLink;
