import React from 'react';

import { title } from './styles.module.scss';

const Title = () => {
  return (
    <p className={title}>
      Check out the awesome pictures we captured! From keynote speakers to the
      evening acts, we've got all the highlights on camera.
    </p>
  );
};

export default Title;
