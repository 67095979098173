import React from 'react';

import Icon from 'components/UI/Icon/index';

import { section, header, title, info } from './styles.module.scss';
import SurveyIframe from 'sections/ScreverOcxProgram/SurveyIframe/index';

const Survey = () => {
  return (
    <section className={section}>
      <div className="ocx-media-wrap">
        <div className={header}>
          <h2 className={title}>Let us know your thoughts</h2>

          <div className={info}>
            <p>
              <Icon name="anonymous" />
              anonymously
            </p>

            <span></span>

            <p>
              <Icon name="clock-2" />1 min
            </p>
          </div>
        </div>

        <SurveyIframe src="https://go.screver.com/capptoo/feedback-after-event" />
      </div>
    </section>
  );
};

export default Survey;
