import React from 'react';
import { Link } from 'gatsby';

import ScreverLogo from './ScreverLogo';
import CapptooLogo from './CapptooLogo';

const CompanyLogo = () => {
  return (
    <>
      <Link to="/" id="link-to-screver-site">
        <ScreverLogo />
      </Link>

      <a
        href="https://capptoo.com/"
        target="_blank"
        rel="noopener noreferrer"
        id="link-to-capptoo-site"
      >
        <CapptooLogo />
      </a>
    </>
  );
};

export default CompanyLogo;
