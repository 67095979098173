import React /*, { useCallback, useEffect, useState, useRef }*/ from 'react';
// import ReactPlayer from 'react-player';

// import Poster from 'images/common/ocx-media-video-poster.png';
import { section, title, iframe } from './styles.module.scss';
// import useWindowSize from 'hooks/useWindowResize';
// import useIntersection from 'hooks/useIntersection';

// import VideoWebm from 'assets/video/Screver-Zweite-Endfassung.webm';
// import VideoMp4 from 'assets/video/Screver-Zweite-Endfassung.mp4';

const Hero = () => {
  // const [isPlaying, setIsPlaying] = useState(false);
  // const { width } = useWindowSize();
  // const player = useRef(null);

  // const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
  //   threshold: 0.1,
  //   triggerOnce: false,
  // });

  // const videoUrl = [VideoWebm, VideoMp4];

  // const handlePlay = useCallback(() => {
  //   if (!isPlaying) {
  //     setIsPlaying(true);
  //   } else {
  //     setIsPlaying(false);
  //   }
  // }, []);

  // useEffect(() => {
  //   if (!inViewSec) {
  //     setIsPlaying(false);
  //     player.current.showPreview();
  //   }
  // }, [inViewSec]);

  return (
    <section className={section}>
      <div className="ocx-media-wrap">
        <h1 className={title}>Watch The Stars of our Event :-&#41;</h1>

        {/* <ReactPlayer
          ref={player}
          width="100%"
          height={width > 800 ? '800px' : '100vw'}
          playing={isPlaying}
          playsinline={true}
          controls={true}
          onClick={handlePlay}
          light={Poster}
          url={videoUrl}
        /> */}

        {/* <iframe
          className={iframe}
          width="100%"
          height="700"
          src="https://www.youtube.com/watch?v=aTaKO9OkJaU"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe> */}

        <iframe
          className={iframe}
          width="100%"
          height="700"
          src="https://www.youtube.com/embed/aTaKO9OkJaU"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          id="Screver-EX-CX-Omnichannel-2023-Highlights"
        ></iframe>
      </div>
    </section>
  );
};

export default Hero;
