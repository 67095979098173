import React from 'react';

const CapptooLogo = () => {
  return (
    <svg
      width="150"
      height="24"
      viewBox="0 0 150 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1025_3277)">
        <path
          d="M63.5447 14.5391C63.2951 15.0354 62.9209 15.5318 62.4219 16.0281C62.0476 16.5244 61.5486 16.8967 60.9248 17.1449C60.4258 17.5171 59.802 17.6412 59.1782 17.8894C58.5544 18.0134 57.9306 18.1375 57.3069 18.1375C56.3088 18.1375 55.4355 18.0134 54.5622 17.6412C53.689 17.2689 52.9404 16.7726 52.3166 16.1522C51.6929 15.5318 51.1938 14.7873 50.8196 13.9187C50.4453 13.0501 50.3206 12.1815 50.3206 11.1888C50.3206 10.1961 50.4453 9.32756 50.8196 8.45897C51.1938 7.59038 51.6929 6.84588 52.3166 6.22546C52.9404 5.60504 53.689 5.1087 54.5622 4.73645C55.4355 4.3642 56.3088 4.24011 57.3069 4.24011C57.9306 4.24011 58.5544 4.3642 59.1782 4.48828C59.802 4.61236 60.301 4.86053 60.9248 5.23278C61.4238 5.60504 61.9228 5.97729 62.4219 6.34954C62.7961 6.84588 63.1704 7.21813 63.5447 7.83855L61.1743 8.83122C60.9248 8.58306 60.8 8.2108 60.4258 7.96264C60.1763 7.71447 59.9267 7.4663 59.5525 7.21813C59.1782 6.96996 58.8039 6.84588 58.4297 6.72179C58.0554 6.59771 57.6811 6.59771 57.3069 6.59771C56.6831 6.59771 56.0593 6.72179 55.5603 6.96996C55.0613 7.21813 54.687 7.59038 54.188 7.96264C53.8137 8.33489 53.4394 8.83122 53.1899 9.45164C52.9404 10.0721 52.8157 10.5684 52.8157 11.1888C52.8157 11.8092 52.9404 12.4297 53.1899 12.926C53.4394 13.4223 53.689 13.9187 54.188 14.415C54.5622 14.7873 55.0613 15.1595 55.5603 15.4077C56.0593 15.6558 56.6831 15.7799 57.3069 15.7799C57.6811 15.7799 58.0554 15.7799 58.4297 15.6558C58.8039 15.5318 59.1782 15.4077 59.4277 15.1595C59.802 14.9113 60.0515 14.7873 60.301 14.415C60.5505 14.1668 60.8 13.9187 61.0495 13.5464L63.5447 14.5391Z"
          fill="white"
        />
        <path
          d="M64.5427 17.8894L71.529 4.3642H71.7785L78.6401 17.8894H75.7707L74.7727 15.7799H68.4101L67.2873 17.8894H64.5427ZM69.6577 13.5464H73.5251L71.6538 9.45164L69.6577 13.5464Z"
          fill="white"
        />
        <path
          d="M87.2487 4.36426C87.8724 4.36426 88.4962 4.48834 89.12 4.73651C89.7438 4.98468 90.2428 5.35693 90.6171 5.72918C90.9913 6.10143 91.3656 6.72186 91.6151 7.21819C91.8646 7.83861 91.9894 8.45903 91.9894 9.07945C91.9894 9.69987 91.8646 10.3203 91.6151 10.9407C91.3656 11.5611 91.1161 12.0575 90.6171 12.4297C90.2428 12.802 89.619 13.1742 88.9952 13.4224C88.3715 13.6706 87.6229 13.7946 86.7496 13.7946H83.7555V17.8894H81.1356V4.36426H87.2487ZM87.2487 11.5611C87.6229 11.5611 87.9972 11.4371 88.2467 11.313C88.4962 11.1889 88.7457 11.0648 88.9952 10.8166C89.12 10.5685 89.3695 10.3203 89.3695 10.0721C89.4943 9.82396 89.4943 9.4517 89.4943 9.20354C89.4943 8.95537 89.4943 8.58312 89.3695 8.33495C89.12 7.9627 88.9952 7.71453 88.7457 7.46636C88.4962 7.21819 88.2467 7.09411 87.9972 6.84594C87.7477 6.72186 87.3734 6.72186 86.8744 6.72186H83.7555V11.5611H87.2487Z"
          fill="white"
        />
        <path
          d="M100.721 4.36426C101.345 4.36426 101.969 4.48834 102.592 4.73651C103.216 4.98468 103.715 5.35693 104.09 5.72918C104.589 6.22552 104.963 6.72186 105.212 7.34228C105.462 7.9627 105.587 8.58312 105.587 9.20354C105.587 9.82396 105.462 10.4444 105.212 11.0648C104.963 11.6852 104.713 12.1816 104.214 12.5538C103.84 12.9261 103.216 13.2983 102.592 13.5465C101.969 13.7946 101.22 13.9187 100.347 13.9187H97.3527V17.8894H94.7328V4.36426H100.721ZM100.721 11.5611C101.095 11.5611 101.47 11.4371 101.719 11.313C101.969 11.1889 102.218 11.0648 102.468 10.8166C102.592 10.5685 102.842 10.3203 102.842 10.0721C102.967 9.82396 102.967 9.4517 102.967 9.20354C102.967 8.95537 102.967 8.58312 102.842 8.33495C102.717 7.9627 102.468 7.71453 102.343 7.46636C102.093 7.21819 101.844 7.09411 101.594 6.84594C101.22 6.72186 100.846 6.72186 100.472 6.72186H97.3527V11.5611H100.721Z"
          fill="white"
        />
        <path
          d="M117.564 6.72186H113.697V17.8894H111.077V6.72186H107.21V4.36426H117.564V6.72186Z"
          fill="white"
        />
        <path
          d="M126.297 18.1375C125.299 18.1375 124.426 18.0134 123.553 17.6412C122.68 17.2689 121.931 16.7726 121.307 16.1522C120.683 15.5318 120.184 14.7873 119.81 13.9187C119.436 13.0501 119.311 12.1815 119.311 11.1888C119.311 10.1961 119.436 9.32756 119.81 8.45897C120.184 7.59038 120.683 6.84588 121.307 6.22546C121.931 5.60504 122.68 5.1087 123.553 4.73645C124.426 4.3642 125.299 4.24011 126.297 4.24011C127.295 4.24011 128.169 4.3642 129.042 4.73645C129.915 5.1087 130.664 5.60504 131.288 6.22546C131.911 6.84588 132.41 7.59038 132.785 8.45897C133.159 9.32756 133.284 10.1961 133.284 11.1888C133.284 12.1815 133.159 13.0501 132.785 13.9187C132.41 14.7873 131.911 15.5318 131.288 16.1522C130.664 16.7726 129.915 17.2689 129.042 17.6412C128.169 18.0134 127.295 18.1375 126.297 18.1375ZM126.297 6.59771C125.674 6.59771 125.05 6.72179 124.551 6.96996C124.052 7.21813 123.553 7.59038 123.179 7.96264C122.804 8.33489 122.43 8.83122 122.181 9.45164C121.931 10.0721 121.806 10.5684 121.806 11.1888C121.806 11.8092 121.931 12.4297 122.181 12.926C122.43 13.4223 122.68 13.9187 123.179 14.415C123.553 14.7873 124.052 15.1595 124.551 15.4077C125.05 15.6558 125.674 15.7799 126.297 15.7799C126.921 15.7799 127.545 15.6558 128.044 15.4077C128.543 15.1595 129.042 14.7873 129.416 14.415C129.791 14.0428 130.165 13.5464 130.414 12.926C130.664 12.4297 130.789 11.8092 130.789 11.1888C130.789 10.5684 130.664 9.94798 130.414 9.45164C130.165 8.83122 129.915 8.45897 129.416 7.96264C129.042 7.59038 128.543 7.21813 128.044 6.96996C127.545 6.72179 126.921 6.59771 126.297 6.59771Z"
          fill="white"
        />
        <path
          d="M142.889 18.1375C141.891 18.1375 141.017 18.0134 140.144 17.6412C139.271 17.2689 138.522 16.7726 137.899 16.1522C137.275 15.5318 136.776 14.7873 136.401 13.9187C136.027 13.0501 135.902 12.1815 135.902 11.1888C135.902 10.1961 136.027 9.32756 136.401 8.45897C136.776 7.59038 137.275 6.84588 137.899 6.22546C138.522 5.60504 139.271 5.1087 140.144 4.73645C141.017 4.3642 141.891 4.24011 142.889 4.24011C143.887 4.24011 144.76 4.3642 145.633 4.73645C146.507 5.1087 147.255 5.60504 147.879 6.22546C148.503 6.84588 149.002 7.59038 149.376 8.45897C149.75 9.32756 149.875 10.1961 149.875 11.1888C149.875 12.1815 149.75 13.0501 149.376 13.9187C149.002 14.7873 148.503 15.5318 147.879 16.1522C147.255 16.7726 146.507 17.2689 145.633 17.6412C144.76 18.0134 143.887 18.1375 142.889 18.1375ZM142.889 6.59771C142.265 6.59771 141.641 6.72179 141.142 6.96996C140.643 7.21813 140.144 7.59038 139.77 7.96264C139.396 8.33489 139.021 8.83122 138.772 9.45164C138.522 10.0721 138.398 10.5684 138.398 11.1888C138.398 11.8092 138.522 12.4297 138.772 12.926C139.021 13.4223 139.271 13.9187 139.77 14.415C140.144 14.7873 140.643 15.1595 141.142 15.4077C141.641 15.6558 142.265 15.7799 142.889 15.7799C143.513 15.7799 144.136 15.6558 144.635 15.4077C145.134 15.1595 145.633 14.7873 146.008 14.415C146.382 14.0428 146.756 13.5464 147.006 12.926C147.255 12.4297 147.38 11.8092 147.38 11.1888C147.38 10.5684 147.255 9.94798 147.006 9.45164C146.756 8.83122 146.507 8.45897 146.008 7.96264C145.633 7.59038 145.134 7.21813 144.635 6.96996C144.136 6.72179 143.513 6.59771 142.889 6.59771Z"
          fill="white"
        />
        <path
          d="M19.5054 0.5177V12.1816L9.77444 6.34965L19.5054 0.5177Z"
          fill="#9CA9FF"
        />
        <path
          d="M19.5056 0.5177V12.1816L29.1118 6.34965L19.5056 0.5177Z"
          fill="#635DC2"
        />
        <path
          d="M19.5055 12.1818V23.9698L29.1116 18.0138L19.5055 12.1818Z"
          fill="#6A3FB9"
        />
        <path
          d="M0.168457 12.1813L9.77464 6.3493V18.0132L0.168457 12.1813Z"
          fill="#73B2FF"
        />
        <path
          d="M29.1115 6.3493V18.0132L38.7176 12.1813L29.1115 6.3493Z"
          fill="#B45EFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1025_3277">
          <rect
            width="149.832"
            height="23.4519"
            fill="white"
            transform="translate(0.168457 0.5177)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CapptooLogo;
