import React from 'react';

import Icon from 'components/UI/Icon';
import SpecialLink from './SpecialLink';

import { socials } from './styles.module.scss';

const Socials = () => {
  return (
    <div className={socials}>
      <a
        href="https://www.facebook.com/screverr"
        target="_blank"
        rel="noopener noreferrer"
        id="link-to-facebook"
      >
        <Icon name="social-fb" />
      </a>

      <a
        href="https://www.instagram.com/screverr/"
        target="_blank"
        rel="noopener noreferrer"
        id="link-to-instagram"
      >
        <Icon name="social-inst" />
      </a>

      <SpecialLink />
    </div>
  );
};

export default Socials;
