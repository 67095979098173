import React from 'react';

const ScreverLogo = () => {
  return (
    <svg
      id="screver-logo-white"
      width="115"
      height="31"
      viewBox="0 0 115 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.6294 19.8396L33.95 18.0074C34.8508 18.994 36.3113 19.883 38.1702 19.883C40.0901 19.883 40.833 18.9362 40.833 18.0291C40.833 15.2068 33.0708 16.9631 33.0708 12.0231C33.0708 9.78621 34.9907 8.0733 37.9334 8.0733C39.9932 8.0733 41.6942 8.75991 42.9144 9.9669L41.5937 11.7196C40.5351 10.6535 39.114 10.1693 37.7109 10.1693C36.3508 10.1693 35.4716 10.8559 35.4716 11.8424C35.4716 14.3612 43.2337 12.8109 43.2337 17.8051C43.2337 20.042 41.6548 21.9753 38.0697 21.9753C35.6116 21.9753 33.8316 21.09 32.6294 19.8396Z"
        fill="white"
      />
      <path
        d="M45.3292 15.2758C45.3292 11.5149 48.0305 8.75659 51.8612 8.75659C54.3554 8.75659 55.8237 9.78692 56.7132 10.9743L54.9013 12.5961C54.1954 11.6166 53.2213 11.1545 51.9883 11.1545C49.7058 11.1545 48.1575 12.8548 48.1575 15.2758C48.1575 17.6969 49.7058 19.4249 51.9883 19.4249C53.2213 19.4249 54.1907 18.9074 54.9013 17.9325L56.7132 19.6051C55.8237 20.7647 54.3507 21.8182 51.8612 21.8182C48.0305 21.8182 45.3292 19.0368 45.3292 15.2758Z"
        fill="white"
      />
      <path
        d="M58.4851 21.5086V9.06615H61.2381V10.8681C62.2076 9.68527 63.7323 8.75659 65.3559 8.75659V11.4364C65.1206 11.3855 64.8571 11.3578 64.5183 11.3578C63.337 11.3578 61.7887 12.1294 61.2381 13.0304V21.504H58.4851V21.5086Z"
        fill="white"
      />
      <path
        d="M66.3084 15.2758C66.3084 11.6674 68.9862 8.75659 72.7369 8.75659C76.5394 8.75659 78.9537 11.5888 78.9537 15.5069V16.1768H69.1932C69.405 18.0295 70.8215 19.6051 73.2075 19.6051C74.4688 19.6051 75.9088 19.1153 76.8265 18.2143L78.0877 19.9932C76.803 21.176 74.9394 21.8228 72.944 21.8228C69.1132 21.8182 66.3084 19.217 66.3084 15.2758ZM72.7087 10.9743C70.3745 10.9743 69.2732 12.7532 69.165 14.2455L76.3041 14.2455C76.2288 12.7763 75.2029 10.9743 72.7087 10.9743Z"
        fill="white"
      />
      <path
        d="M84.6655 21.5076L79.4982 9.06519H82.463L86.162 18.3889L89.861 9.06519H92.8023L87.635 21.5076H84.6655Z"
        fill="white"
      />
      <path
        d="M93.3476 15.2758C93.3476 11.6674 96.0253 8.75659 99.7761 8.75659C103.579 8.75659 105.993 11.5888 105.993 15.5069V16.1768H96.2324C96.4442 18.0295 97.8607 19.6051 100.247 19.6051C101.508 19.6051 102.948 19.1153 103.866 18.2143L105.127 19.9932C103.842 21.176 101.979 21.8228 99.9831 21.8228C96.1524 21.8182 93.3476 19.217 93.3476 15.2758ZM99.7478 10.9743C97.4136 10.9743 96.3124 12.7532 96.2041 14.2455L103.343 14.2455C103.263 12.7763 102.242 10.9743 99.7478 10.9743Z"
        fill="white"
      />
      <path
        d="M107.765 21.5086V9.06615H110.518V10.8681C111.487 9.68527 113.012 8.75659 114.636 8.75659V11.4364C114.401 11.3855 114.137 11.3578 113.798 11.3578C112.617 11.3578 111.069 12.1294 110.518 13.0304V21.504H107.765V21.5086Z"
        fill="white"
      />
      <path
        d="M6.32137 29.9082L14.2821 27.9324C14.2821 27.9324 17.599 27.0543 15.6088 23.3223C15.6088 23.3223 13.4943 19.3708 12.2504 17.4829C11.0066 15.5949 5.28482 7.6919 5.03605 4.79413C5.03605 4.79413 -1.47347 10.3262 2.2581 16.034C5.98968 21.7417 7.56523 23.8492 7.56523 23.8492C7.56523 23.8492 10.0529 27.186 6.32137 29.9082Z"
        fill="white"
      />
      <path
        d="M16.4801 0.579834L8.51937 2.55559C8.51937 2.55559 5.20241 3.4337 7.19258 7.16568C7.19258 7.16568 9.30714 11.1172 10.551 13.0051C11.7949 14.8931 17.5166 22.84 17.7654 25.6939C17.7654 25.6939 24.2749 20.1618 20.5433 14.454C16.8117 8.74629 15.2362 6.68272 15.2362 6.68272C15.2362 6.68272 12.7485 3.30199 16.4801 0.579834Z"
        fill="white"
      />
      <path
        d="M15.2365 6.68198C15.2365 6.68198 15.6096 7.20885 16.4803 8.43821C16.4803 8.43821 19.4241 6.63808 19.8802 6.24293C20.3778 5.84778 23.6947 3.56468 22.5752 1.23768C21.4558 -1.08932 16.4803 0.579098 16.4803 0.579098C12.7488 3.30125 15.2365 6.68198 15.2365 6.68198Z"
        fill="url(#paint0_linear_1025_3292)"
      />
      <path
        d="M7.56588 23.8053C7.56588 23.8053 7.19272 23.2784 6.32202 22.0491C6.32202 22.0491 3.37823 23.8492 2.92215 24.2444C2.46607 24.6395 -0.892349 26.9226 0.227122 29.2496C1.34659 31.5766 6.32202 29.9082 6.32202 29.9082C10.0536 27.186 7.56588 23.8053 7.56588 23.8053Z"
        fill="url(#paint1_linear_1025_3292)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1025_3292"
          x1="14.4203"
          y1="4.2158"
          x2="22.8061"
          y2="4.2158"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1025_3292"
          x1="8.36406"
          y1="26.2862"
          x2="-0.0216491"
          y2="26.2862"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0" />
          <stop offset="0.8528" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ScreverLogo;
