import React from 'react';

import CompanyLogoWrap from 'sections/ScreverOcxProgram/components/CompanyLogoWrap';

import { section, inner } from './styles.module.scss';

const Header = () => {
  return (
    <section className={section}>
      <div className="ocx-media-wrap">
        <div className={inner}>
          <CompanyLogoWrap />
        </div>
      </div>
    </section>
  );
};

export default Header;
