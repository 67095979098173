import React from 'react';

// import { IFRAME_HTML } from 'mocks/screver-ocx-program/iframe-html';

import { wrap } from './styles.module.scss';

const SurveyIframe = ({ src, style = null }) => {
  return (
    <div className={wrap} style={style}>
      <iframe title="Survey" src={src} frameBorder="0"></iframe>
    </div>
  );
};

export default SurveyIframe;
