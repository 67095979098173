import React from 'react';

import Header from 'sections/ScreverOcxMedia/Header';
import Hero from 'sections/ScreverOcxMedia/Hero';
import Gallery from 'sections/ScreverOcxMedia/Gallery';
// import Explore from 'sections/ScreverOcxMedia/Explore';
import Footer from 'sections/ScreverOcxProgram/Footer';
import Survey from 'sections/ScreverOcxMedia/Survey';
import SEO from 'sections/Layout/SEO';
import OcxHelmet from 'sections/Layout/SEO/OcxHelmet';

import 'sections/ScreverOcxMedia/common.scss';

const seo = {
  title: 'OCX Event | Photos | Screver Feedback Management Solution',
  metaDesc:
    'View photos from the Screver OCX Event. We’ll share some exciting news, show off a sneak peek of innovative features, and showcase how AI shapes VoC.',
  image:
    'https://wp.screver.com/wp-content/uploads/2023/08/screver-ocx-meta-img.jpeg',
  opengraphUrl: 'https://screver.com/events/screver-ocx/media/',
};

const OcxEventMedia = () => {
  return (
    <>
      <OcxHelmet robots={'noindex, nofollow, nosnippet'} />

      <SEO seo={seo} themeColor={'#151517'} />

      <div className="scr-ocx-event-media">
        <Header />

        <main>
          <Hero />
          <Gallery />
          {/* TODO: proxy server disabled */}
          {/* <Explore /> */}
          <Survey />
        </main>

        <div className="ocx-footer-wrap ocx-media-wrap">
          <Footer />
        </div>
      </div>
    </>
  );
};

export default OcxEventMedia;
