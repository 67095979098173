import React /*, { useState, useEffect, useMemo }*/ from 'react';
import classnames from 'classnames';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// import axios from 'axios';

import useStaticImage from 'hooks/graphql/useStaticImage';
// import useIntersection from 'hooks/useIntersection';

import { listStyle, grid } from './styles.module.scss';
import './animation.scss';

// import Img1 from 'images/ocx/media/grid/webp/1.webp';
// import Img2 from 'images/ocx/media/grid/webp/2.webp';
// import Img3 from 'images/ocx/media/grid/webp/3.webp';
// import Img4 from 'images/ocx/media/grid/webp/4.webp';
// import Img5 from 'images/ocx/media/grid/webp/5.webp';
// import Img6 from 'images/ocx/media/grid/webp/6.webp';
// import Img7 from 'images/ocx/media/grid/webp/7.webp';
// import Img8 from 'images/ocx/media/grid/webp/8.webp';
// import Img9 from 'images/ocx/media/grid/webp/9.webp';
// import Img10 from 'images/ocx/media/grid/webp/10.webp';
// import Img11 from 'images/ocx/media/grid/webp/11.webp';
// import Img12 from 'images/ocx/media/grid/webp/12.webp';
// import Img13 from 'images/ocx/media/grid/webp/13.webp';
// import Img14 from 'images/ocx/media/grid/webp/14.webp';
// import Img15 from 'images/ocx/media/grid/webp/15.webp';
// import Img16 from 'images/ocx/media/grid/webp/16.webp';
// import Img17 from 'images/ocx/media/grid/webp/17.webp';

const images = [
  // Img1,
  // Img2,
  // Img3,
  // Img4,
  // Img5,
  // Img6,
  'ocx/media/grid/webp/1.webp',
  'ocx/media/grid/webp/2.webp',
  'ocx/media/grid/webp/3.webp',
  'ocx/media/grid/webp/17.webp',
  'ocx/media/grid/webp/10.webp',
  'ocx/media/grid/webp/16.webp',
];

const images2 = [
  // Img7,
  // Img8,
  // Img9,
  // Img10,
  // Img11,
  // Img12,
  'ocx/media/grid/webp/4.webp',
  'ocx/media/grid/webp/5.webp',
  'ocx/media/grid/webp/6.webp',
  'ocx/media/grid/webp/7.webp',
  'ocx/media/grid/webp/8.webp',
  'ocx/media/grid/webp/9.webp',
];

// const images3 = [
//   'ocx/media/grid/8.webp',
//   'ocx/media/grid/9.webp',
//   'ocx/media/grid/10.webp',
//   'ocx/media/grid/16.webp',
// ];

// const images4 = [
//   'ocx/media/grid/11.webp',
//   'ocx/media/grid/12.webp',
//   'ocx/media/grid/13.webp',
//   'ocx/media/grid/14.webp',
// ];

// const images5 = [
//   'ocx/media/grid/15.webp',
//   'ocx/media/grid/16.webp',
//   'ocx/media/grid/17.webp',
//   'ocx/media/grid/1.webp',
// ];

const array = [images, images2 /*, images3, images4, images5*/];

const Grid = () => {
  const getStaticImage = useStaticImage();
  // const { scrollWrapper, inViewSec, inViewRefSec } = useIntersection({
  //   threshold: 0,
  // });

  // const [imageData, setImageData] = useState([]);

  // useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       const response = await axios.get(
  //         'https://screver-ocx-event.test-dev.site/proxy.php'
  //       );

  //       setImageData(response.data);

  //       console.log(imageData, 'imageData');
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   getData();
  // }, []);

  // console.log(imageData, 'imageData');

  return (
    <>
      <div>
        <div className={classnames(grid, 'jsAnimInit')}>
          {array.map((list) => {
            return (
              <ul key={list} className={listStyle}>
                {list.map((img) => {
                  const image = getImage(getStaticImage(img).childImageSharp);

                  return (
                    <li key={img}>
                      <GatsbyImage
                        image={image}
                        alt={'alt'}
                        placeholder="blurred"
                        loading="eager"
                      />

                      {/* <img src={img} alt="" /> */}
                    </li>
                  );
                })}
              </ul>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Grid;
