import React from 'react';
import Grid from './Grid';

import { section } from './styles.module.scss';
import Title from './Title';

const Gallery = () => {
  return (
    <section className={section}>
      <div className="ocx-media-wrap">
        <Title />
      </div>

      <Grid />
    </section>
  );
};

export default Gallery;
