import React from 'react';

import CompanyLogoWrap from '../components/CompanyLogoWrap';
import Socials from './Socials';

import { inner, companies } from './styles.module.scss';

const Footer = () => {
  return (
    <footer>
      <div className="ocx-prog-wrap">
        <div className={inner}>
          <div className={companies}>
            <CompanyLogoWrap />
          </div>

          <Socials />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
